import HeroSection from './sections/hero';
import CounterSection from './sections/counterSection';
import PoemSection from './sections/poemSection';
import InformationSection from './sections/informationSection';
import TimelineSection from './sections/timelineSection';
import HotelsSection from './sections/hotelsSection';
import PlayListSection from './sections/playListSection';
import DessCodeSection from './sections/dressCodeSection';
import GiftTable from './sections/giftTable';
import ConsiderationsSection from './sections/considerationsSection';
import CollageSection from './sections/collageSection';
import FooterSection from './sections/footerSection';

import './global.css';

function App() {

  const currentDate = new Date();
  console.log(currentDate);

  return (
    
    <div className="App">
      <HeroSection />
      <CounterSection />
      <PoemSection />
      <InformationSection />
      <TimelineSection />
      <PlayListSection />
      <HotelsSection />
      <DessCodeSection />
      <GiftTable />
      <ConsiderationsSection />
      <CollageSection />
      <FooterSection />
    </div>
  );
}

export default App;
